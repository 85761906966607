import { useEffect } from 'react';
import { setGlobal } from 'utils/globals';
import { hideOfflineAlert, showOfflineAlert } from 'utils/offline';
export function useOnlineStatus() {
  useEffect(() => {
    const onOnline = () => {
      setGlobal('UserIsOnline', true);
      hideOfflineAlert();
    };
    const onOffline = () => {
      setGlobal('UserIsOnline', false);
      showOfflineAlert();
    };
    window.addEventListener('online', onOnline);
    window.addEventListener('offline', onOffline);
    return () => {
      window.removeEventListener('online', onOnline);
      window.removeEventListener('offline', onOffline);
    };
  }, []);
}