import { AlertMessage } from '@clubhouse/shared/components/AlertMessage';
import { Text } from '@clubhouse/shared/components/Typography/Text';
import { openFeedbackForm } from 'utils/feedback';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ProjectsInfoBanner = () => {
  return ___EmotionJSX(AlertMessage, {
    size: AlertMessage.SIZE.SMALL,
    kind: AlertMessage.KIND.INFO
  }, ___EmotionJSX(AlertMessage.Title, null, "Try Teams and Custom Fields"), ___EmotionJSX(Text, {
    bold: true
  }, "Projects is a legacy feature. We recommend checking out our help documentation on how to leverage", ___EmotionJSX("a", {
    href: KNOWLEDGE_BASE_SLUGS.TEAMS,
    target: "_blank",
    rel: "noopener noreferrer"
  }, "\xA0Teams"), "\xA0and", ___EmotionJSX("a", {
    href: KNOWLEDGE_BASE_SLUGS.CUSTOM_FIELDS,
    target: "_blank",
    rel: "noopener noreferrer"
  }, "\xA0Custom Fields"), ", instead. For questions about Projects, please reach out to our", ___EmotionJSX("a", {
    href: "#",
    onClick: () => openFeedbackForm()
  }, "\xA0Support Team"), "."));
};
ProjectsInfoBanner.displayName = "ProjectsInfoBanner";