import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.structured-clone.js";
import { getApolloClient } from 'gql/utils/initApollo';
import { useEffect } from 'react';
import { getValue, setValue } from 'utils/localStorage';
import { getUpdatesUrl } from 'utils/updates';
import { setGlobal } from 'utils/globals';
import { trigger } from 'utils/event';
const getUrl = () => {
  const path = '/api/private/sse-updates';
  return getUpdatesUrl(path);
};
const clearCache = async () => {
  const client = await getApolloClient();
  await client?.cache.reset();
};
let lastSeenDbTime = 0;
const getDbTime = () => lastSeenDbTime || getValue('datalayer-db-time', {
  defaultValue: 0,
  prefix: true
});
const setDbTime = dbTime => {
  const numDbTime = dbTime !== null ? Number(dbTime) : null;
  if (typeof numDbTime === 'number') {
    lastSeenDbTime = numDbTime;
    setValue('datalayer-db-time', dbTime, {
      prefix: true
    });
  }
};
const getState = s => s === EventSource.OPEN ? 'open' : s === EventSource.CONNECTING ? 'connecting' : 'closed';
const parseUpdatePayload = payload => (payload || '').split('\n').map(str => {
  try {
    return JSON.parse(str.trim());
  } catch {
    return null;
  }
}).filter(Boolean);
const getEventSourceUrl = workspaceId => {
  return `${getUrl()}/${workspaceId}/${getDbTime()}?legacy-updates=1`;
};
const createEventSource = (url, onMessage, onStateChange) => {
  const eventSource = new EventSource(url, {
    withCredentials: true
  });
  onStateChange(getState(eventSource.readyState));
  eventSource.addEventListener('error', () => onStateChange(getState(eventSource.readyState)));
  eventSource.addEventListener('open', () => onStateChange(getState(eventSource.readyState)));
  eventSource.addEventListener('reset', event => onMessage({
    type: 'reset',
    data: null,
    dbTime: event.lastEventId
  }));
  eventSource.addEventListener('update', event => {
    const data = parseUpdatePayload(event.data);
    if (data?.length) onMessage({
      type: 'update',
      data,
      dbTime: event.lastEventId
    });
  });
  eventSource.addEventListener('legacy-update', event => {
    const data = parseUpdatePayload(event.data);
    if (data?.length) onMessage({
      type: 'legacy-update',
      data,
      dbTime: event.lastEventId
    });
  });
  return eventSource;
};
let eventSource = null;
export function useEventSource(_ref) {
  let {
    onUpdate,
    onLegacyUpdate,
    publicId,
    enabled
  } = _ref;
  useEffect(() => {
    if (!enabled || typeof publicId !== 'string') return;
    if (eventSource) return;
    eventSource = createEventSource(getEventSourceUrl(publicId), async _ref2 => {
      let {
        type,
        data,
        dbTime
      } = _ref2;
      switch (type) {
        case 'reset':
          {
            console.debug('SSE - Reset');
            trigger('datalayer-reset');
            onLegacyUpdate({
              type: 'reset',
              dbTime
            });
            setDbTime(dbTime);
            await clearCache();
            break;
          }
        case 'update':
          {
            setDbTime(dbTime);
            onUpdate(data);
            break;
          }
        case 'legacy-update':
          {
            onLegacyUpdate({
              type: 'update',
              data,
              dbTime
            });
            trigger('datalayer-update', {
              data: structuredClone(data),
              dbTime
            });
            break;
          }
      }
    }, state => {
      console.debug('SSE - EventSource state changed: ', state);
      switch (state) {
        case 'open':
          setGlobal('enable-datalayer-updates', true);
          trigger('enable-datalayer', true);
          break;
        case 'closed':
          setGlobal('enable-datalayer-updates', false);
          trigger('enable-datalayer', false);
          break;
      }
    });
    return () => {
      trigger('enable-datalayer', false);
      if (eventSource) {
        console.debug('SSE - EventSource state changed: closed');
        eventSource.close();
        eventSource = null;
      }
    };
  }, [onUpdate, onLegacyUpdate, enabled, publicId]);
}