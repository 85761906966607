import "core-js/modules/es.array.push.js";
import ProfileModel from 'app/client/core/js/models/profile.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Model', 'Profile'], ProfileModel], [['Model', 'Profile'], ProfileModel]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { LoadingDots } from '@clubhouse/shared/animations';
import { Avatar } from '@clubhouse/shared/components/Avatar';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { UnstyledButton } from '@clubhouse/shared/components/UnstyledButton';
import { useToggleState } from '@clubhouse/shared/hooks';
import { capitalize } from '@clubhouse/shared/utils';
import { isLoggedInUserAdmin } from 'data/entity/user';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function DisabledUser(_ref2) {
  let {
    profile,
    scimEnabled,
    onRestoreUser
  } = _ref2;
  const [isRestoring, toggleRestoring] = useToggleState();
  return ___EmotionJSX("div", {
    className: "profile-container",
    "data-model": "Profile",
    "data-id": profile.id
  }, ___EmotionJSX(Avatar, {
    profile: profile,
    size: Avatar.SIZE.XL
  }), ___EmotionJSX("div", {
    className: "info-container"
  }, ___EmotionJSX("div", {
    className: "name"
  }, profile.name), ___EmotionJSX("div", {
    className: "username"
  }, "@", profile.mention_name), ___EmotionJSX("div", {
    className: "email"
  }, ___EmotionJSX("a", {
    href: `mailto:${profile.email_address}`
  }, profile.email_address))), ___EmotionJSX("div", {
    className: "role-dropdown-container"
  }, ___EmotionJSX("div", {
    className: "role disabled"
  }, capitalize(profile.role))), isLoggedInUserAdmin() && !scimEnabled && ___EmotionJSX("div", {
    className: "actions"
  }, ___EmotionJSX(UnstyledButton, {
    disabled: isRestoring,
    onClick: async () => {
      try {
        toggleRestoring.on();
        await onRestoreUser(profile.id);
      } catch {} finally {
        toggleRestoring.off();
      }
    },
    "data-tooltip": `Restore ${profile.name}`
  }, isRestoring ? ___EmotionJSX(LoadingDots, null) : ___EmotionJSX(SizedIcon, {
    icon: "Sync",
    label: `Restore ${profile.name}`
  }))));
}
DisabledUser.displayName = "DisabledUser";