import { ApolloProvider } from '@apollo/client';
import { useState } from 'react';
import { useAsync } from 'react-use';
import { isCanceled, useCancelablePromises } from '@clubhouse/shared/hooks';
import { UseQueryContext } from 'gql/hooks/UseQueryContext';
import { QueryWorkspace2Id } from 'gql/hooks/useWorkspace2PublicId';
import { isLoggedInPage } from 'utils/is';
import { useWorkspaceSlug } from 'utils/navigation/useWorkspaceSlug';
import { createLink, initApollo } from '../utils/initApollo';
import { DataLayerUpdatesSSEProvider } from './updates/DataLayerUpdatesSSEProvider';
import { EvictCacheOnUpdate } from './updates/EvictCacheOnUpdate';
import { LegacyUpdatesBroadcaster } from './updates/LegacyUpdatesBroadcaster';
import { jsx as ___EmotionJSX } from "@emotion/react";
const updateLinkFromNetworkIfNeeded = async _ref => {
  let {
    publicIdFromCache,
    client,
    slug,
    makeCancelable
  } = _ref;
  try {
    const {
      data: dataFromNetwork
    } = await makeCancelable(client.query({
      query: QueryWorkspace2Id,
      variables: {
        slug
      },
      fetchPolicy: 'network-only'
    }));
    if (dataFromNetwork?.workspace2?.publicId !== publicIdFromCache) {
      client.setLink(createLink({
        workspace2Id: dataFromNetwork?.workspace2?.publicId
      }));
    }
  } catch {}
};
export const ApolloWrapper = _ref2 => {
  let {
    children
  } = _ref2;
  const slug = useWorkspaceSlug();
  const [client, setClient] = useState(null);
  const {
    makeCancelable
  } = useCancelablePromises();
  useAsync(async () => {
    const _client = await makeCancelable(initApollo(createLink()));
    if (slug && isLoggedInPage()) {
      try {
        const {
          data,
          error
        } = await makeCancelable(_client.query({
          query: QueryWorkspace2Id,
          variables: {
            slug
          }
        }));
        if (!error) {
          _client.setLink(createLink({
            workspace2Id: data?.workspace2?.publicId
          }));
          updateLinkFromNetworkIfNeeded({
            client: _client,
            slug,
            publicIdFromCache: data?.workspace2?.publicId,
            makeCancelable
          });
        }
      } catch (e) {
        if (isCanceled(e)) return;
      }
    }
    setClient(_client);
  }, [slug]);
  if (!client) return null;
  return ___EmotionJSX(ApolloProvider, {
    client: client
  }, ___EmotionJSX(DataLayerUpdatesSSEProvider, null, ___EmotionJSX(LegacyUpdatesBroadcaster, null, ___EmotionJSX(EvictCacheOnUpdate, null, ___EmotionJSX(UseQueryContext, null, children)))));
};
ApolloWrapper.displayName = "ApolloWrapper";