import * as CreateOrgOrWorkspaceTemplate from 'app/client/login/views/templates/createOrgOrWorkspace.html';
import * as SignUpSuccessfulTemplate from 'app/client/login/views/templates/signUpSuccessful.html';
import { FEATURE_TOGGLES, getFeatureToggle, updateLDWithUser } from '@clubhouse/feature-toggles';
import { Problem } from 'components/shared/Problem';
import { StackOverflowPixel } from 'components/login/StackOverflowPixel';
import Backend from '../../../core/js/modules/backend';
import CompanyModel from '../../../core/js/models/company';
import * as Event from '../../../core/js/_frontloader/event';
import Is from '../../../core/js/modules/is';
import Log from '../../../core/js/modules/log';
import Router from '../../../core/js/_frontloader/router';
import Url from '../../../core/js/modules/url';
import Utils from '../../../core/js/modules/utils';
import Validator from '../../../core/js/modules/validator';
import View from '../../../core/js/modules/view';
import FlashController from '../../../core/js/controllers/flash';
import OrganizationModel from '../../../core/js/models/organization';
import PaymentPlan2Model from '../../../core/js/models/paymentPlan2';
import User from '../../../core/js/modules/user';
import { getDeviceType } from 'utils/device';
import { EntitlementViolationDialog } from 'components/settings/EntitlementViolationDialog';
import { PLAN_TABLE_TRIGGER_LOCATIONS, sendPlanTableOpenedEvent } from 'components/settings/manage-billing/tracking';
import { setPageTitle } from 'utils/page';
import { PAGE_NAMES, generatePathForPage } from 'utils/navigation';
import SpaceModel from '../../../core/js/models/space';
import { fetchAndGetTeamScopedUrl, fetchAndGetTeamScopedUrlForUser } from 'data/entity/group';
import { jsx as ___EmotionJSX } from "@emotion/react";
const exports = {};
const PARENT_SELECTOR = '#signup-form';
exports.DEFAULT_REDIRECT_PAGE = PAGE_NAMES.STORIES;
let isSaving = false;

// TODO(rename)
exports.KEY = 'ClubhouseSignUpState';
let state = {};
const getStatusIconElementForSelector = selector => document.querySelector(selector).closest('.form-input').querySelector('.status-icon');
exports.init = () => {
  state = {
    page: 1,
    formData: {}
  };
  const params = Url.parseLocationSearch();
  const entityType = params.org ? 'Workspace' : 'Organization';
  setPageTitle(`Create your ${entityType} | ${BRAND.NAME}`);
  if (params.org) {
    CompanyModel.fetch(params.org, (_, company) => {
      if (company) {
        exports.render(company);
      } else {
        // Strips the company param if it's invalid due to the user not
        // being an owner, or if they're not a member of that Company.
        Router.replaceState('/signup');
        exports.render();
      }
    });
  } else {
    exports.render();
  }
};
exports.render = company => {
  View.renderWithComponents({
    template: CreateOrgOrWorkspaceTemplate,
    templateData: {
      company,
      page: getState().page,
      formData: getFormData()
    },
    templateMountNode: document.querySelector('#form-container')
  });
  Utils.fadePageIn();
  Validator.init(PARENT_SELECTOR);
  Validator.validateOnKeyup(PARENT_SELECTOR);
  $('#org-name').focus();
  Event.trigger('resize');
  Event.trigger('pageRendered', 'Signup');
};
exports.renderViolationDialog = () => {
  sendPlanTableOpenedEvent(PLAN_TABLE_TRIGGER_LOCATIONS.WORKSPACE_CREATION);
  return View.renderComponentDelayed({
    componentKey: 'EntitlementViolationDialog',
    component: ___EmotionJSX(EntitlementViolationDialog, null)
  }).html;
};
const getFormData = () => {
  return getState().formData;
};
const updateState = updates => {
  state = _.merge(state, updates);
  return state;
};
const getState = () => {
  return state;
};
exports.validateOrgName = () => {
  exports.validateParameter('#org-name', 'organization_name', () => {
    const element = $('#org-name').parent().find('.status-icon');
    element.html('<span class="fa fa-spin fa-star"></span>');
  });
  const value = $('#org-name').val();
  const lastCheckedValue = $('#org-name').data('last-checked-value');
  if (value === '' || value === lastCheckedValue) {
    return false;
  }
  const urlElement = $('#org-url');
  const suggestedUrl = Utils.suggestUsername(value);
  if (urlElement.val() === '') {
    urlElement.removeClass('manual-override');
  }
  if (!urlElement.hasClass('manual-override')) {
    urlElement.val(suggestedUrl);
  }
  exports.validateOrgURL();
  return false;
};
exports.validateOrgURL = function () {
  if (this.id === 'org-url') {
    $(this).addClass('manual-override');
  }
  exports.validateParameter('#org-url', 'organization_url', () => {
    const element = getStatusIconElementForSelector('#org-url');
    element.innerHTML = '<span class="fa fa-spin fa-star"></span>';
  });
  return false;
};
const validateTimeouts = {};
exports.validateParameter = function (selector, name, beforeCallback, afterCallback) {
  let {
    abortIfAlreadyChecked = true
  } = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
  const value = $(selector).val().trim();
  const lastCheckedValue = $(selector).data('last-checked-value');
  if (value === '') {
    const element = getStatusIconElementForSelector(selector);
    element.innerHTML = '';
  }
  if (value === '' || abortIfAlreadyChecked && value === lastCheckedValue) {
    return false;
  }
  if (validateTimeouts[selector]) {
    clearTimeout(validateTimeouts[selector]);
  }

  // Waiting here for user to finish typing.
  validateTimeouts[selector] = setTimeout(() => {
    const element = $(selector);
    const value = element.val();
    const data = {};
    data[name] = value;
    $(selector).data('last-checked-value', value);
    if (_.isFunction(beforeCallback)) {
      beforeCallback();
    }
    Backend.post('/api/private/signups/check', {
      data,
      onComplete: res => {
        exports.onValidationResponse(res, selector, afterCallback);
      }
    });
  }, 400);
};
exports.onValidationResponse = (res, selector, callback) => {
  const element = getStatusIconElementForSelector(selector);
  if (_.isPlainObject(res) && res.valid === true) {
    element.innerHTML = '<span class="fa fa-check"></span>';
  } else {
    element.innerHTML = '<span class="fa fa-times" data-reason="' + (res.reason || '') + '"></span>';
  }
  Validator.validate(PARENT_SELECTOR + ' .active-page');
  if (_.isFunction(callback)) {
    callback(res);
  }
};
function _setButtonToCreatingState(context) {
  context.find('button.action').html('Creating <span class="fa fa-spin fa-star"></span>').addClass('disabled').attr('disabled', 'disabled');
}
exports.submitExistingUser = () => {
  if (isSaving) {
    return false;
  }
  isSaving = true;
  updateState({
    formData: {
      organization_name: $('#org-name').val().trim(),
      organization_url: $('#org-url').val().trim().toLowerCase()
    }
  });
  const context = $(PARENT_SELECTOR + ' .signup-page-one');
  const company = Utils.getModelFromContext(context, 'Company');
  const companyHeader = company ? {
    company_id: company.id
  } : {};
  const endpoint = _getEndpointForExistingUsers(company);
  const formData = getFormData();
  const data = {
    name: formData.organization_name
  };

  // TODO: Which property should we consolidate to?
  data[company ? 'url_slug' : 'slug'] = formData.organization_url;
  if (Validator.isValid(context)) {
    _setButtonToCreatingState(context);
    Backend.post('/api/private/' + endpoint, _.assign(companyHeader, {
      data,
      excludeOrgHeader: true,
      onComplete: (res, xhr) => {
        if (xhr.status === 201) {
          Log.log('New workspace created', {
            organization: formData.organization_name
          });
          exports.renderSuccess({
            existingUser: true,
            company,
            organizationOrWorkspace: res
          });
        } else {
          exports.renderError(res);
        }
        isSaving = false;
      }
    }));
  } else {
    isSaving = false;
  }
  return false;
};
function _getEndpointForExistingUsers(company) {
  // If creating an org within a company, owners and admins have separate endpoints
  if (company) {
    return CompanyModel.isOwner() ? 'owner/workspaces2' : 'admin/workspaces2';
  }

  // If it's not part of a company, we create a new company
  // which will also create an org with the same name.
  return 'organizations2';
}
exports.renderError = res => {
  const context = $(PARENT_SELECTOR);
  const button = context.find('button.disabled');
  if (res && res.error) {
    // We don't want to log errors related to `entitlement-violation` as they are within expected boundaries.
    if (res.reason_tag !== 'entitlement-violation') {
      Log.error(new Error(res.error), {
        originalResponse: res
      });
    }
    const message = res.message || res.error;
    FlashController.error(context, 'Unable to create workspace', message, true);
    button.html('Finish <span class="fa fa-arrow-right"></span>').removeClass('disabled').removeAttr('disabled');
  } else {
    button.html('Create Workspace <span class="fa fa-arrow-right">').removeClass('disabled').removeAttr('disabled');
  }
};
exports.removeServerErrors = () => {
  $(PARENT_SELECTOR).find('.server-messages').html('').hide();
};
exports.renderSuccess = options => {
  options = options || {};
  Utils.fadePageOut();
  setTimeout(async () => {
    const paddingTop = Is.mobile() ? 60 : Math.max(80, $(window).height() * 0.2);
    $('#content').css({
      paddingTop
    });
    const entityType = options.company ? 'Workspace' : 'Organization';
    View.renderWithComponents({
      template: SignUpSuccessfulTemplate,
      templateData: options,
      templateMountNode: document.querySelector('#content'),
      components: {
        stackOverflowPixel: {
          component: StackOverflowPixel,
          props: {
            xsp: 4491140
          }
        },
        accountCreated: {
          component: Problem,
          props: {
            height: '100vh',
            icon: React.createElement('span', {
              className: 'fa fa-check-circle'
            }),
            title: `${options.existingUser ? entityType : 'Account'} Created!`,
            description: React.createElement(React.Fragment, {}, ['Logging in...', React.createElement('span', {
              className: 'fa fa-star fa-spin'
            })])
          }
        }
      }
    });
    Utils.fadePageIn();
    if (options.existingUser) {
      const workspace = options.organizationOrWorkspace?.workspaces2?.[0] ?? options.organizationOrWorkspace;
      const baseUrl = generatePathForPage(exports.DEFAULT_REDIRECT_PAGE, {
        slug: workspace.url_slug
      });
      const {
        pathname,
        search
      } = await fetchAndGetTeamScopedUrl(baseUrl, workspace.id, window.location.origin);
      Utils.redirect(`${pathname}${search}`);
    } else {
      setTimeout(() => {
        redirectToApp();
      }, 2000);
    }
  }, 400);
};
exports.redirectFromSignup = async permissionId => {
  try {
    const org = OrganizationModel.getCurrent();
    const company = CompanyModel.getCurrent();
    const paymentPlan = PaymentPlan2Model.getPlanForCurrentOrg();
    if (!org || !company || !paymentPlan) {
      const errorMessage = [org ? '' : 'Missing org data!', company ? '' : 'Missing company data!', paymentPlan ? '' : 'Missing payment plan data!'].join(' ');
      throw new Error(errorMessage);
    } else {
      const trialingStatus = PaymentPlan2Model.planTrialInfo(paymentPlan);
      const currentProfile = {
        current_wksp2_slug: org.url_slug,
        current_org2_slug: company.slug,
        current_org_id: org.id,
        current_org_name: org.name,
        current_org_created_at: org.created_at,
        current_slug: company.slug,
        current_company_id: company.id,
        current_company_created_at: company.created_at,
        current_product_id: paymentPlan.current_product_id,
        current_trial_state: trialingStatus?.trial_days_left,
        current_pending_product_id: PaymentPlan2Model.getPendingProduct(paymentPlan)?.id
      };
      const user = User.getCurrentUser();

      // Despite the type warning here, I think the types work out.
      // The User type in user.d.ts seems to just be incomplete,
      // and the user object actually satisfies ApiUser.
      await updateLDWithUser({
        user,
        currentProfile,
        device: getDeviceType()
      });
    }
  } catch (e) {
    Log.error(e);
  } finally {
    redirectToApp(permissionId);
  }
};
const redirectToApp = async permissionId => {
  const org = OrganizationModel.getCurrent();
  const storiesV2Enabled = getFeatureToggle(FEATURE_TOGGLES.STORIES_PAGE_V2_REPLACE_STORIES_PAGE) === 'enabled';
  const activeSpace = SpaceModel.getActive();
  if (!org || !activeSpace) {
    Log.error(new Error([org ? '' : 'Missing org data!', activeSpace ? '' : 'No active space found'].join(' ')));
  }
  const pathParams = {
    slug: org?.url_slug
  };

  // activeSpace is always a v1 space, so when the user is using v2 page, we can't use it.
  // Instead we redirect to "/stories" and let the page update the url (same as if user clicked on "Stories" in nav).
  if (!storiesV2Enabled && activeSpace) {
    pathParams.spaceOrEntityType = 'space';
    pathParams.id = activeSpace.id;
  }
  const baseUrl = generatePathForPage(PAGE_NAMES.STORIES, pathParams);
  const {
    pathname,
    search
  } = await fetchAndGetTeamScopedUrlForUser(`${baseUrl}?from=signup`, org?.id, permissionId, window.location.origin);
  const redirectUrl = `${pathname}${search}`;

  // Fix for bug SC-230578 that caused users to load the app without a premade workspace and showed empty stories
  Utils.redirect(redirectUrl);
};
export { exports as default };