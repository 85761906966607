import "core-js/modules/es.array.push.js";
/* eslint-disable no-restricted-globals */
import LocalStorage from 'app/client/core/js/modules/localStorage';
import { getCurrentUserPrefix } from 'data/entity/user';

/** @deprecated */
export const get = LocalStorage.get;

/** @deprecated */
export const set = LocalStorage.set;

/** @deprecated */
export const remove = LocalStorage.remove;

/** @deprecated */
export const listen = LocalStorage.listen;

/* New implementations. Preferred. */

const withPrefix = (key, prefix) => {
  if (!prefix) return key;
  const prefixStr = prefix === true ? getCurrentUserPrefix() : `${prefix}.`;
  return `${prefixStr}${key}`;
};
const parseValue = rawValue => {
  try {
    const {
      val,
      exp
    } = JSON.parse(rawValue);
    if (!exp || exp > Date.now()) return val;
  } catch {}
  return null;
};
export const getValue = function (key) {
  let {
    prefix,
    raw,
    defaultValue = null
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const rawValue = LocalStorage.get(withPrefix(key, prefix));
  if (!rawValue) return defaultValue;
  if (raw) return rawValue;
  const parsedValue = parseValue(rawValue);
  if (parsedValue === null) return defaultValue;
  return parsedValue;
};
export const setValue = function (key, val) {
  let {
    prefix,
    raw,
    ttl
  } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  if (raw) {
    LocalStorage.set(withPrefix(key, prefix), val);
    return;
  }
  const item = {
    val
  };
  if (ttl) item.exp = Date.now() + ttl;
  LocalStorage.set(withPrefix(key, prefix), JSON.stringify(item));
};
export const removeValue = function (key) {
  let {
    prefix
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  LocalStorage.remove(withPrefix(key, prefix));
};

/* Util methods: */

export const getLargestItem = () => {
  try {
    return Object.entries(localStorage).reduce((acc, _ref) => {
      let [key, value] = _ref;
      if (acc.value.length > value.length) {
        return acc;
      }
      return {
        key,
        value
      };
    }, {
      key: '',
      value: ''
    });
  } catch (e) {}
  return {
    key: '',
    value: ''
  };
};
export const isQuotaExceeded = () => {
  const key = String(Math.random());
  try {
    localStorage.setItem(key, 'test-value');
  } catch (e) {
    if (String(e).toLowerCase().includes('quota')) {
      return true;
    }
  }
  try {
    localStorage.removeItem(key);
  } catch (e) {}
  return false;
};
export const getSizeInBytes = () => {
  try {
    return Object.entries(localStorage).reduce((acc, _ref2) => {
      let [key, value] = _ref2;
      return acc + (key.length + value.length) * 2; // 2x for utf-16
    }, 0);
  } catch (e) {}
  return 0;
};
export const findMatchingKeys = function (partialKey) {
  let {
    prefix,
    raw
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const found = [];
  for (const [key, value] of Object.entries(localStorage)) {
    if (key.startsWith(withPrefix(partialKey, prefix))) {
      if (raw) found.push([key, value]);else found.push([key, parseValue(value)]);
    }
  }
  return found;
};

/* eslint-enable no-restricted-globals */