import Spaceship from '@clubhouse/assets/png/spaceship-min.png';
import { EmptyState } from 'components/shared/EmptyState';
import { ErrorBoundary } from 'components/shared/ErrorBoundary';
import { PageContent } from 'components/shared/PageContent';
import { Init } from 'utils/boot';
import { useSetPageTitle } from 'utils/navigation/useSetPageTitle';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const PageNotFoundContent = _ref => {
  let {
    actions,
    className
  } = _ref;
  return ___EmotionJSX(EmptyState, {
    className: className,
    image: ___EmotionJSX("img", {
      src: Spaceship,
      alt: "",
      style: {
        height: 300
      }
    }),
    title: "Oops, something went wrong!",
    actions: actions,
    size: EmptyState.Size.Large
  }, "The page you are looking for was not found.");
};
PageNotFoundContent.displayName = "PageNotFoundContent";
export const ContentNotFound = _ref2 => {
  let {
    children,
    className
  } = _ref2;
  const Content = PageNotFoundContent;
  return ___EmotionJSX(Content, {
    actions: children,
    className: className
  });
};
ContentNotFound.displayName = "ContentNotFound";
export const PageNotFound = () => {
  useSetPageTitle({
    title: '404 | ' + BRAND.NAME
  });
  return ___EmotionJSX(PageContent, null, ___EmotionJSX(ContentNotFound, null));
};
PageNotFound.displayName = "PageNotFound";
export const PageNotFoundWithInit = _ref3 => {
  let {
    renderId
  } = _ref3;
  return ___EmotionJSX(ErrorBoundary, {
    pageName: "404"
  }, ___EmotionJSX(Init, {
    page: "404",
    pageTitle: "404",
    renderId: renderId
  }, ___EmotionJSX(PageNotFound, null)));
};
PageNotFoundWithInit.displayName = "PageNotFoundWithInit";