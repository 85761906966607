import { Text } from '@clubhouse/shared/components/Typography/Text';
import { HelpMessage } from 'components/shared/HelpMessage';
import { DisabledUser } from './DisabledUser';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function DisabledUsersDirectory(_ref) {
  let {
    users,
    scimEnabled,
    onRestoreUser
  } = _ref;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", null, ___EmotionJSX(HelpMessage, {
    kind: "info"
  }, "Disabled users remain in the system, but can't log in and aren't included in your active user count.", !scimEnabled && ___EmotionJSX(React.Fragment, null, "Disabled users can be restored at any time."))), users.length ? ___EmotionJSX("div", {
    className: "people-list"
  }, users.map(profile => ___EmotionJSX(DisabledUser, {
    key: profile.id,
    profile: profile,
    scimEnabled: scimEnabled,
    onRestoreUser: onRestoreUser
  }))) : ___EmotionJSX(Text, {
    size: Text.SIZE.SMALL,
    color: Text.COLOR.LIGHT
  }, "There are no disabled users."));
}