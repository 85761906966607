import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import { useCallback, useMemo, useRef } from 'react';
import { FEATURE_TOGGLES, useVariation } from 'utils/launch-darkly';
import { useWorkspace2PublicId } from '../../hooks/useWorkspace2PublicId';
import { DataLayerUpdatesContext } from './DataLayerUpdatesContext';
import { useOnlineStatus } from './useOnlineStatus';
import { useEventSource } from './useEventSource';
import { useDocumentVisibility } from '@clubhouse/shared/hooks/useDocumentVisibility';
import { getCurrentPage, PAGE_NAMES } from 'utils/navigation';
import { getCurrentOrgId, isOrgDisabled } from 'data/entity/organization';
import { jsx as ___EmotionJSX } from "@emotion/react";
const useDataLayerUpdates = () => {
  const {
    value: datalayerUpdatesEndpointEnabled
  } = useVariation(FEATURE_TOGGLES.DATALAYER_UPDATES_ENDPOINT);
  const {
    value: storiesV3Enabled
  } = useVariation(FEATURE_TOGGLES.STORIES_V3);
  return Boolean(datalayerUpdatesEndpointEnabled || storiesV3Enabled);
};
const isBillingPageAndOrgOrWorkspaceIsDisabled = () => {
  return getCurrentPage() === PAGE_NAMES.SETTINGS_MANAGE_BILLING && isOrgDisabled(getCurrentOrgId());
};
export function DataLayerUpdatesSSEProvider(_ref) {
  let {
    children
  } = _ref;
  const listenersRef = useRef(new Set());
  const legacyListenersRef = useRef(new Set());
  const publicId = useWorkspace2PublicId();
  const canUseDataLayerUpdates = useDataLayerUpdates();
  const isActive = useDocumentVisibility() === 'visible';
  const enabled = canUseDataLayerUpdates && !isBillingPageAndOrgOrWorkspaceIsDisabled() && isActive;
  useOnlineStatus();
  useEventSource({
    onUpdate: useCallback(data => listenersRef.current.forEach(fn => fn(data)), []),
    onLegacyUpdate: useCallback(event => legacyListenersRef.current.forEach(fn => fn(event)), []),
    publicId,
    enabled
  });
  const registerListener = useCallback(fn => {
    listenersRef.current.add(fn);
    return () => {
      listenersRef.current.delete(fn);
    };
  }, []);
  const registerLegacyListener = useCallback(fn => {
    legacyListenersRef.current.add(fn);
    return () => {
      legacyListenersRef.current.delete(fn);
    };
  }, []);
  return ___EmotionJSX(DataLayerUpdatesContext.Provider, {
    value: useMemo(() => ({
      registerListener,
      registerLegacyListener
    }), [registerListener, registerLegacyListener])
  }, children);
}
DataLayerUpdatesSSEProvider.displayName = "DataLayerUpdatesSSEProvider";