import ActivityModel from '../models/activity';
import Ajax from './ajax';
import BulkSelectionModel from '../models/bulkSelection';
import Backend from './backend';
import ConsolidatedFetch from './consolidatedFetch';
import EpicModel from '../models/epic';
import EstimateScaleModel from '../models/estimateScale';
import * as Event from '../_frontloader/event';
import FasterMoment from './fasterMoment';
import Globals from '../_frontloader/globals';
import GroupModel from '../models/group';
import InstallationModel from '../models/installation';
import InviteModel from '../models/invite';
import Iterate from './iterate';
import IterationModel from '../models/iteration';
import IntegrationModel from '../models/integration';
import ImportModel from '../models/import';
import LabelModel from '../models/label';
import Log from './log';
import LogoutController from '../controllers/logout';
import MilestoneModel from '../models/milestone';
import OrganizationModel from '../models/organization';
import ProjectModel from '../models/project';
import RepositoryModel from '../models/repository';
import SpaceModel from '../models/space';
import StoryDialogController from '../controllers/storyDialog';
import StoryModel from '../models/story';
import StoryTemplateModel from '../models/storyTemplate';
import TeamModel from '../models/team';
import ProfileModel from '../models/profile';
import WebhookModel from '../models/webhook';
import FeatureModel from '../models/feature';
import CustomFieldModel from '../models/customField';
import Tests from './tests';
import Url from './url';
import Utils from './utils';
import ManageBillingController from '../../../settingsShared/js/controllers/manageBilling';
import { hideOfflineAlert, showOfflineAlert } from 'utils/offline';
const exports = {};
const QUICK_POLL = 1000; // in cases where the user is dragging or saving, in milliseconds
const POLL = 1000 * 5; // in milliseconds
const IDLE_POLL = 1000 * 60; // in milliseconds
const MAX_AGE_OF_LAST_POLL = 1000 * 90; // in milliseconds
let shouldRenderWhenDocumentIsVisible;
exports.wasJustUpdated = story => {
  return FasterMoment.diff(story.updated_at) < POLL;
};
exports.updateDatabaseTime = xhr => {
  const time = exports.getDatabaseTimeFromXhr(xhr);
  if (time) {
    exports.setTime(time);
  }
};
exports.getTime = () => {
  return Globals.get('databaseTime');
};
exports.setupSSEListeners = () => {
  Event.onlyOn('enable-datalayer', enabled => {
    if (enabled) exports.cancel();else exports.poll();
  });
  Event.onlyOn('datalayer-update', _ref => {
    let {
      data,
      dbTime
    } = _ref;
    if (dbTime) exports.setTime(dbTime);
    for (const update of data) {
      _handleUpdates(update, () => {
        _updatePage(update);
      });
    }
  });
  Event.onlyOn('datalayer-reset', () => {
    ConsolidatedFetch.fetchConsolidatedDataAndActivity(() => {
      _updatePage(null);
    });
  });
};
exports.init = renderFn => {
  Event.onlyOn('pageDestroy.Updates', () => {
    exports.cancel();
  });
  exports.setupSSEListeners();
  _setPageRenderFn(renderFn);
  exports.poll();
};
exports.cancel = () => {
  removeVisibilityListeners();
  _destroyPoll();
};
function _getHeaders(xhr) {
  const headers = {};
  if (xhr && xhr.getResponseHeader) {
    // TODO(rename) Should headers change?
    headers.ClubhouseOrganization2 = xhr.getResponseHeader('Tenant-Organization2');
    headers.ClubhouseWorkspace2 = xhr.getResponseHeader('Tenant-Workspace2');
  }
  return headers;
}
exports.getUpdatesUrl = path => {
  const origin = Url.getCurrentOrigin();
  const shortcut = origin.match(/^https:\/\/(app\.shortcut(?:-[^.]+)?\.com)$/);
  if (shortcut) {
    // Shortcut in any environment
    // route to the appropriate api subdomain to avoid the cost of using Fastly for updates traffic
    return 'https://api.' + shortcut[1] + path;
  } else if (window._UPDATES_DOMAIN) {
    // LOCAL DEV + LOCAL BACKEND
    // when developing both node-app and backend locally
    return window._UPDATES_DOMAIN + path;
  }

  // LOCAL DEV + STAGING BACKEND (or PR ENV)
  // route through the same domain as the app is being served from
  // this case occurs when running node-app locally connected to staging,
  // and in the PR review environments
  return origin + '/backend' + path;
};
const getUpdatesURL = () => {
  const path = '/api/private/updates/';
  return exports.getUpdatesUrl(path);
};
const isOrgManagementPage = () => {
  return Url.getSlugPath() === '' || Url.getCurrentPage() === 'organizations';
};
const isBillingPageAndOrgOrWorkspaceIsDisabled = () => {
  return Url.getCurrentPathname() === ManageBillingController.route() && OrganizationModel.isDisabled(OrganizationModel.getFromCurrentSlug().id) === true;
};
exports.checkForUpdates = function () {
  let {
    withPagination = document.hidden,
    time = exports.getTime(),
    callback = _.noop
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let retryCount = arguments.length > 1 ? arguments[1] : undefined;
  if (!time) return false;
  if (Globals.get('enable-datalayer-updates') === true) {
    exports.setupSSEListeners();
    callback?.();
    return false;
  }
  if (isOrgManagementPage() || isBillingPageAndOrgOrWorkspaceIsDisabled()) return false;
  const MAX_RETRIES = 3;

  // It's possible that the connection has been dropped, causing the last
  // update poll to fail. We need to guard against that here.
  // Ref: https://app.shortcut.com/internal/story/12812/update-polling-can-get-out-of-sync-if-client-goes
  const isChecking = _getIsChecking();
  if (isChecking && moment().diff(isChecking) < MAX_AGE_OF_LAST_POLL) {
    exports.poll();
    return false;
  }
  _setIsChecking(Date.now());

  // Avoid the CDN for updates requests
  // Ref: https://app.shortcut.com/internal/story/45276/move-updates-endpoint-off-of-fastly
  Ajax.get(getUpdatesURL() + time, {
    shouldPreventPreflight: true,
    data: {
      pagination: withPagination
    },
    onComplete: (res, xhr) => {
      if (xhr.status === 0) {
        showOfflineAlert();
      } else if (xhr.status === 200) {
        hideOfflineAlert();
      }
      if (xhr.status === 401 || xhr.status === 403) {
        retryCount = _.isNumber(retryCount) ? retryCount + 1 : 1;
        if (_.isNumber(retryCount) && retryCount >= MAX_RETRIES) {
          // Unauthorized response, user logged out of another window.
          // Looks like the status code changed from 401 to 403 recently, but let's support both just in case.
          // 401 Unauthorized is pretty close in meaning anyway.
          Log.log('Updates request responded with a ' + xhr.status + ' response code, logging user out...', _getHeaders(xhr));
          return LogoutController.logoutAndRedirect();
        } else {
          Log.log('Updates request responded with a ' + xhr.status + ' response code, retrying ' + retryCount + ' of ' + MAX_RETRIES + '...', _getHeaders(xhr));
          _setIsChecking(false);
          return exports.checkForUpdates({
            withPagination,
            time,
            callback
          }, retryCount);
        }
      }
      if (xhr.status === 503 || xhr.status === 400) {
        _setIsChecking(false);
        if (document.hidden) {
          shouldRenderWhenDocumentIsVisible = false;
          return;
        }

        // clear Database Time to allow the api calls to set it
        // appropriately
        clearTime();
        ConsolidatedFetch.fetchConsolidatedDataAndActivity(() => {
          _updatePage(null, {
            callback
          });
          exports.poll();
        });
        return;
      }
      if (res && res.updates === true) {
        if (Globals.get('isDragging')) {
          Log.debug('Updates: updates found, but user is dragging.');
          callback();
        } else if (Globals.get('isSaving')) {
          Log.debug('Updates: updates found, but something is mid-save.');
          callback();
        } else {
          Log.debug('Updates: updates found, processing updates...');
          exports.updateDatabaseTime(xhr);
          _handleUpdates(res, () => {
            if (document.hidden) {
              shouldRenderWhenDocumentIsVisible = true;
              return;
            }
            _updatePage(res, {
              callback
            });
          });
        }
      } else {
        Log.debug('Updates: no updates found for ' + time);
        exports.updateDatabaseTime(xhr);
        if (!ActivityModel.hasNewActivity()) {
          Event.trigger('noNewActivity');
        }
        callback();
      }
      _setIsChecking(false);
    }
  });
  exports.poll();
};
function _updatePage(res) {
  let {
    callback = _.noop
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  _callPageRenderFn(res);
  ActivityModel.fetchSinceLastCheck();
  callback();
}
function _handleUpdates(res, callback) {
  callback = _.isFunction(callback) ? callback : _.noop;
  if (res.updated_members) {
    _handleModelUpdates({
      model: ProfileModel,
      name: 'Profile'
    }, res.updated_members);
  }
  if (res.updated_spaces) {
    _handleModelUpdates({
      model: SpaceModel,
      name: 'Space'
    }, res.updated_spaces, {
      beforeUpdate: space => {
        // Prevent Spaces shared by other users from showing in the Space list of the current user.
        if (space.shared && typeof space.hidden !== 'boolean') {
          space.hidden = true;
        }
        return space;
      }
    });
  }
  if (res.updated_milestones) {
    _handleModelUpdates({
      model: MilestoneModel,
      name: 'Milestone'
    }, res.updated_milestones);
  }
  if (res.updated_installations) {
    _handleModelUpdates({
      model: InstallationModel,
      name: 'Installation'
    }, res.updated_installations);
  }
  if (res.updated_integrations) {
    _handleModelUpdates({
      model: IntegrationModel,
      name: 'Integration'
    }, res.updated_integrations);
  }
  if (res.updated_webhooks) {
    _handleModelUpdates({
      model: WebhookModel,
      name: 'Webhook'
    }, res.updated_webhooks);
  }
  if (res.updated_estimate_scales) {
    _handleModelUpdates({
      model: EstimateScaleModel,
      name: 'EstimateScale'
    }, res.updated_estimate_scales);
  }
  if (res.updated_invites) {
    _handleModelUpdates({
      model: InviteModel,
      name: 'Invite'
    }, res.updated_invites);
  }
  if (res.updated_entity_templates) {
    _handleModelUpdates({
      model: StoryTemplateModel,
      name: 'StoryTemplate'
    }, res.updated_entity_templates);
  }
  if (res.updated_repositories) {
    _handleModelUpdates({
      model: RepositoryModel,
      name: 'Repository'
    }, res.updated_repositories);
  }
  if (res.updated_groups) {
    _handleModelUpdates({
      model: GroupModel,
      name: 'Group'
    }, res.updated_groups);
  }
  if (res.updated_workspace2) {
    OrganizationModel.updateIfValid(res.updated_workspace2);
  }
  if (res.updated_stories) {
    _handleStoryUpdates(res.updated_stories);
  }
  if (res.updated_epics) {
    _handleModelUpdates({
      model: EpicModel,
      name: 'Epic'
    }, res.updated_epics);
  }
  if (res.updated_labels) {
    _handleModelUpdates({
      model: LabelModel,
      name: 'Label'
    }, res.updated_labels);
  }
  if (res.updated_teams) {
    _handleModelUpdates({
      model: TeamModel,
      name: 'Team'
    }, res.updated_teams);
  }
  if (res.updated_projects) {
    _handleModelUpdates({
      model: ProjectModel,
      name: 'Project'
    }, res.updated_projects);
  }
  if (Tests.usesIterations() && res.updated_iterations) {
    _handleModelUpdates({
      model: IterationModel,
      name: 'Iteration'
    }, res.updated_iterations);
  }
  if (!_.isEmpty(res.updated_workspace2_settings)) {
    handleWorkspaceUpdates(res.updated_workspace2_settings);
  }
  if (res.updated_imports) {
    _handleModelUpdates({
      model: ImportModel,
      name: 'Import'
    }, res.updated_imports);
  }
  if (res.updated_features) {
    _handleModelUpdates({
      model: FeatureModel,
      name: 'Feature'
    }, res.updated_features);
  }
  if (res.updated_custom_fields) {
    _handleModelUpdates({
      model: CustomFieldModel,
      name: 'CustomFields'
    }, res.updated_custom_fields);
  }
  callback();
}
function handleWorkspaceUpdates(_ref2) {
  let {
    iterations_enabled
  } = _ref2;
  if (iterations_enabled) {
    IterationModel.fetchAll(() => Event.trigger('iterationsEnabled'));
  } else if (iterations_enabled === false) {
    Event.trigger('iterationsDisabled');
  }
}
function _getCurrentStoryMobile() {
  const element = $('.story-page');
  if (element.length > 0) {
    return Utils.getModelFromContext(element);
  }
}
function _handleStoryUpdates(updates) {
  let changesMade = false;
  let fetchAndRedrawCurrentStoryDialog = false;
  let fetchAndRedrawCurrentStoryMobile = false;
  const currentStoryDialog = StoryDialogController.getCurrentlyDisplayedStory();
  const currentStoryMobile = _getCurrentStoryMobile();
  Iterate.each(updates.deleted, id => {
    changesMade = true;
    const story = StoryModel.getById(id);
    if (story) {
      BulkSelectionModel.removeFromSelection(story.id);
      Log.debug('Updates: story #' + id + ' was deleted');
      Event.trigger('somebodyDeletedStory', story);
      StoryModel.remove({
        id
      });
    }
  });
  StoryModel.trigger('bulkStart');
  Iterate.each(updates.position_map, update => {
    changesMade = true;
    const existing = StoryModel.getById(update.id);
    if (existing) {
      Log.debug('Updates: updating position on story #' + update.id + ': ' + update.position);
      existing.position = update.position;

      // We can get workflow_state_id if a Project is moved to a different Team,
      // or if a story is moved by way of a GitHub event.
      if (update.workflow_state_id) {
        existing.workflow_state_id = update.workflow_state_id;

        // If the story was moved by way of a GitHub event,
        // it won't show up in the modified array, so we need
        // to handle the fetch and redraw here.
        if (currentStoryDialog && currentStoryDialog.id === existing.id) {
          fetchAndRedrawCurrentStoryDialog = true;
        } else if (currentStoryMobile && currentStoryMobile.id === existing.id) {
          fetchAndRedrawCurrentStoryMobile = true;
        }
      }
    }
  });
  Iterate.each(updates.modified, story => {
    changesMade = true;
    Log.debug('Updates: story #' + story.id + ' was changed or added');

    // The current user (or any user) may have just created a story with a new label,
    // which we don't otherwise know about, so let's add those new labels here.
    // Ref: https://app.shortcut.com/internal/story/21200/creating-a-label-from-the-story
    Iterate.each(story.labels, label => {
      if (!LabelModel.getById(label.id)) {
        LabelModel.updateIfValid(label);
      }
    });
    StoryModel.updateIfValid(story);
    if (currentStoryDialog && currentStoryDialog.id === story.id) {
      fetchAndRedrawCurrentStoryDialog = true;
      Event.trigger('currentStory.Updated', story);
    } else if (currentStoryMobile && currentStoryMobile.id === story.id) {
      fetchAndRedrawCurrentStoryMobile = true;
      Event.trigger('currentStory.Updated', story);
    }
  });
  StoryModel.trigger('bulkEnd');
  if (fetchAndRedrawCurrentStoryDialog) {
    StoryModel.fetchStory(currentStoryDialog.id, () => {
      StoryDialogController.update({
        force: true
      });
    });
  } else if (fetchAndRedrawCurrentStoryMobile) {
    StoryModel.fetchStory(currentStoryMobile.id);
  }
  if (changesMade) {
    StoryModel.sortStoriesByPosition();
  }
}
function _handleModelUpdates(type, updates, opts) {
  const Model = type.model;
  const name = type.name;
  const {
    beforeUpdate = obj => obj
  } = opts ?? {};
  Model.trigger('syncStart');
  Iterate.each(updates.deleted, id => {
    const obj = Model.get({
      id
    });
    if (obj) {
      Log.debug(`Updates: ${name} #${id} was deleted`);
      Model.remove({
        id
      });
      Event.trigger('somebodyDeleted' + name, obj);
    }
  });
  Iterate.each(updates.modified, obj => {
    Log.debug(`Updates: ${name} #${obj.id} was changed or added`);
    const modifiedObj = beforeUpdate(obj);
    Model.updateIfValid(modifiedObj);
    Event.trigger('somebodyModified' + name, modifiedObj);
  });
  updates.position_map?.forEach(update => {
    if (update) {
      const {
        id,
        position
      } = update;
      Model.updatePosition?.({
        id,
        position
      });
    }
  });
  Model.trigger('syncEnd');
}
function _destroyPoll() {
  const id = Globals.get('UpdatesTimeoutID');
  if (id) {
    clearTimeout(id);
  }
}
const restoreIfDocumentIsNotHidden = function () {
  let logData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (document.hidden) {
    return;
  }
  removeVisibilityListeners();
  Backend.post('/log', {
    data: {
      documentHidden: document.hidden,
      ...logData
    }
  });
  Log.debug('Updates: visibility restored, polling now');
  if (shouldRenderWhenDocumentIsVisible) {
    shouldRenderWhenDocumentIsVisible = false;
    _updatePage(null);
  }
  exports.poll(() => {}, 0);
};
let pollingIntervalOverride = null;
exports.setPollingIntervalOverride = val => {
  pollingIntervalOverride = val;
};
function getPollIntervalForVisibilityState() {
  if (document.hidden) {
    Log.debug('Updates: using idle polling interval of ' + IDLE_POLL);
    return IDLE_POLL;
  } else {
    if (pollingIntervalOverride) {
      return pollingIntervalOverride;
    }
    return POLL;
  }
}
const removeVisibilityListeners = () => {
  $(window).off('visibilitychange.UpdatesVisibilityHandler');
  $(document).off('mousemove.UpdatesVisibilityHandler');
};
const addVisibilityChangeListeners = () => {
  removeVisibilityListeners();
  $(window).on('visibilitychange.UpdatesVisibilityHandler', () => {
    restoreIfDocumentIsNotHidden({
      updatesRestoreOverridden: false
    });
  });

  // fallback for restoring visiibility
  $(document).on('mousemove.UpdatesVisibilityHandler', () => {
    restoreIfDocumentIsNotHidden({
      updatesRestoreOverridden: true
    });
  });
};
exports.poll = (callback, pollMs) => {
  if (document.hidden) {
    addVisibilityChangeListeners();
  }
  callback = _.isFunction(callback) ? callback : _.noop;
  pollMs = _.isNumber(pollMs) ? pollMs : getPollIntervalForVisibilityState();
  _destroyPoll();
  Globals.set('UpdatesTimeoutID', setTimeout(() => {
    const time = exports.getTime();
    if (!time) {
      return false;
    }
    if (Globals.get('isDragging')) {
      Log.debug('Updates: not checking because user is dragging.');
      exports.poll(callback, QUICK_POLL);
    } else if (Globals.get('isSaving')) {
      Log.debug('Updates: not checking because something is mid-save.');
      exports.poll(callback, QUICK_POLL);
    } else {
      exports.checkForUpdates({
        callback
      });
    }
  }, pollMs));
};
function clearTime() {
  Globals.set('databaseTime', null);
}
exports.setTime = time => {
  if (time) {
    Globals.set('databaseTime', Number(time));
  }
  return Globals.get('databaseTime');
};
exports.getDatabaseTimeFromXhr = xhr => {
  if (xhr && xhr.getResponseHeader) {
    return Number(xhr.getResponseHeader('Database-Time'));
  }
};
function _callPageRenderFn(res) {
  // Some pages explicitly don't re-render on update, because it could be jarring.
  const pageRenderFn = _getPageRenderFn();
  if (_.isFunction(pageRenderFn)) {
    Log.debug('Updates: calling pageRenderFn...');
    return pageRenderFn(res);
  }
}
function _setIsChecking(value) {
  return Globals.set('UpdatesIsChecking', value);
}
function _getIsChecking() {
  return Globals.get('UpdatesIsChecking');
}
function _setPageRenderFn(fn) {
  return Globals.set('UpdatesPageRenderFn', fn);
}
function _getPageRenderFn() {
  return Globals.get('UpdatesPageRenderFn');
}
export { exports as default };