function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { EpicHealthFieldFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { EpicHealthUpdateSelect } from 'components/gql/epics/EpicHealthUpdateSelect';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const EpicHealthFieldFragment = EpicHealthFieldFragmentDoc;
export function EpicHealthFieldComponent(_ref) {
  let {
    entity,
    onOpen,
    onClose,
    onChangeCurrent,
    onChangeHistory
  } = _ref;
  return ___EmotionJSX(EpicHealthUpdateSelect, _extends({}, entity, {
    showCommentCount: true,
    onOpen: onOpen,
    onClose: onClose,
    onChangeCurrent: onChangeCurrent,
    onChangeHistory: onChangeHistory
  }));
}
EpicHealthFieldComponent.displayName = "EpicHealthFieldComponent";
export const EpicHealthField = () => ({
  name: 'currentHealthStatus',
  displayName: 'Health',
  Component: EpicHealthFieldComponent,
  LoadingComponent: null,
  width: 150,
  sort: true
});