import { Spaced } from '@clubhouse/shared/components/Spaced';
import { addToast, hasToast, removeToast, ToastText, ToastTitle } from '@clubhouse/shared/components/Toast';
import { getCurrentPage } from './navigation';
import { jsx as ___EmotionJSX } from "@emotion/react";
const OFFLINE_ID = 'user-is-offline-toast';
const ONLINE_ID = 'user-is-online-toast';
const OfflineToast = () => ___EmotionJSX(Spaced, {
  vertically: true,
  amount: "space2"
}, ___EmotionJSX(ToastTitle, null, "Reconnecting..."), ___EmotionJSX(ToastText, null, "You will not be able to save changes until your connection is restored."));
OfflineToast.displayName = "OfflineToast";
const WriteOfflineToast = () => ___EmotionJSX(Spaced, {
  vertically: true,
  amount: "space2"
}, ___EmotionJSX(ToastTitle, null, "You're Offline"), ___EmotionJSX(ToastText, null, "We're trying to reconnect you now..."));
WriteOfflineToast.displayName = "WriteOfflineToast";
export const showOfflineAlert = () => {
  const isWrite = getCurrentPage() === 'write';
  removeToast(ONLINE_ID);
  addToast({
    id: OFFLINE_ID,
    customIcon: 'Offline',
    kind: isWrite ? 'info' : 'alert',
    Content: isWrite ? WriteOfflineToast : OfflineToast
  });
};
export const hideOfflineAlert = () => {
  if (hasToast(OFFLINE_ID)) {
    removeToast(OFFLINE_ID);
    addToast({
      id: ONLINE_ID,
      customIcon: 'CheckCircle',
      kind: 'info',
      timeout: 2500,
      Content: () => ___EmotionJSX(ToastText, null, "Connection restored.")
    });
  }
};