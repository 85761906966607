import "core-js/modules/es.array.push.js";
import { QueryCycleTimeDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useState } from 'react';
import { gql } from '@clubhouse/datalayer';
import { CHARTS } from '@clubhouse/shared/types';
import { insertIf as insertObjectIf } from '@clubhouse/shared/utils/object';
import { Explanation } from 'components/reports/cycleTime/Explanation';
import { useWorkspaceUtcOffset } from 'data/entity/organization';
import { useQuery } from 'gql';
import { FEATURE_TOGGLES, useVariation } from 'utils/launch-darkly';
import { getCurrentPage, useWorkspaceSlug } from 'utils/navigation';
import { causify } from '@clubhouse/shared/utils';
import { useReportConfigState } from '../hooks/useReportConfigState';
import { ChartErrorBoundary } from '../reports-v2/ChartErrorBoundary';
import { ChartLoadingOverlay } from '../reports-v2/ChartLoadingOverlay';
import { CHART_NO_DATA_HEIGHT } from '../reports-v2/ChartUnstartedUtils';
import { ReportCard } from '../reports-v2/ReportCard';
import { ReportCardHeaderEmptyState } from '../reports-v2/ReportCardHeaderEmptyState';
import { CYCLE_TIME_CHART_HEIGHT, CYCLE_TIME_WORKFLOW_ID_SEARCH_PARAM } from '../reports-v2/constants';
import { ChartTypeSelect } from './ChartTypeSelect';
import { CycleTime } from './CycleTime';
import { CycleTimeSkeleton } from './CycleTimeSkeleton';
import { ScaleSelect } from './ScaleSelect';
import { TimeUnitSelect } from './TimeUnitSelect';
import { WorkflowSelect } from './WorkflowSelect';
import { WorkflowStateRangeSelect } from './WorkflowStateRangeSelect';
import { generateScaleSelectorObject } from './utils';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const QUERY_CYCLE_TIME_REPORT = QueryCycleTimeDocument;
const ALL_STORY_TYPES = ['feature', 'bug', 'chore'];
const CHART_ID = CHARTS.CYCLE_TIME;
const CUSTOM_CHART_OPTION_VALUE = ['custom'];
function isCustomChartTypeOptionValue(optionValue) {
  return CUSTOM_CHART_OPTION_VALUE.includes(optionValue);
}
const CycleTimeReportCardInner = _ref => {
  let {
    iterationId,
    iterationStatus,
    startDate,
    endDate
  } = _ref;
  const [chartType, setChartType] = useState(null);
  const [scale, setScale] = useState('log');
  const [timeUnit, setTimeUnit] = useState(null);
  const [{
    workflowId
  }, setWorkflowId] = useReportConfigState(CYCLE_TIME_WORKFLOW_ID_SEARCH_PARAM, iterationId);
  const [uncheckedStoryTypes, setUncheckedStoryTypes] = useState(null);
  const [startingWorkflowStateIds, setStartingWorkflowStateIds] = useState([]);
  const [endingWorkflowStateIds, setEndingWorkflowstateIds] = useState([]);
  const [openStatesbyDefault, setOpenStatesByDefault] = useState(false);
  const timeUnitSelectorEnabled = useVariation(FEATURE_TOGGLES.ITERATION_REPORTS_V2_TIME_UNIT_SELECTOR).value;
  const slug = useWorkspaceSlug();
  const isUnstartedIteration = iterationStatus === 'unstarted';
  const selectStoryTypesInput = ALL_STORY_TYPES.filter(storyType => !uncheckedStoryTypes?.includes(storyType));
  const {
    data,
    previousData,
    loading,
    error,
    refetch: refetchChartData
  } = useQuery(QUERY_CYCLE_TIME_REPORT, {
    variables: {
      slug,
      input: {
        entityId: iterationId,
        range: {
          start: startDate,
          end: endDate
        },
        ...((chartType || workflowId || uncheckedStoryTypes || timeUnit) && {
          viewConfig: {
            ...(chartType && {
              selectChartType: chartType
            }),
            ...(workflowId && {
              selectWorkflowID: workflowId
            }),
            ...insertObjectIf(!!timeUnit, {
              selectTimeUnit: timeUnit
            }),
            ...insertObjectIf(!!uncheckedStoryTypes, {
              selectStoryTypes: selectStoryTypesInput
            }),
            ...insertObjectIf(chartType === 'custom' && endingWorkflowStateIds.length > 0 && startingWorkflowStateIds.length > 0, {
              selectWorkflowStateCycle: {
                endingWorkflowStateIds,
                startingWorkflowStateIds
              }
            })
          }
        })
      }
    }
  });
  const workspaceUtcOffset = useWorkspaceUtcOffset();
  if (loading && !previousData) return ___EmotionJSX(CycleTimeSkeleton, null);
  if (error) {
    throw new Error('Error loading cycle time chart data: ', {
      cause: causify(error)
    });
  }
  const chartData = (data ?? previousData)?.workspace2?.cycleLeadTimeReport;
  const viewConfig = data?.workspace2?.cycleLeadTimeReport?.viewConfig;
  const handleSetChartType = chartType => {
    setChartType(chartType);
    if (chartType === 'custom' && !openStatesbyDefault) {
      setOpenStatesByDefault(true);
    }
    if (chartType !== 'custom' && openStatesbyDefault) {
      setOpenStatesByDefault(false);
    }
  };
  const handleSetScale = scale => setScale(scale);
  const handleWorkflowChange = workflowId => {
    setWorkflowId({
      workflowId
    });
    setStartingWorkflowStateIds([]);
    setEndingWorkflowstateIds([]);
  };
  const handleSetTimeUnit = timeUnit => setTimeUnit(timeUnit);
  const handleToggleStoryType = storyType => {
    const updatedUncheckedStoryTypes = uncheckedStoryTypes?.slice() ?? [];
    if (!uncheckedStoryTypes?.length) {
      updatedUncheckedStoryTypes.push(storyType);
      setUncheckedStoryTypes(updatedUncheckedStoryTypes);
      return;
    }
    const storyTypeIdx = uncheckedStoryTypes.findIndex(uncheckedStoryType => uncheckedStoryType === storyType);
    if (storyTypeIdx !== -1) {
      updatedUncheckedStoryTypes.splice(storyTypeIdx, 1);
    } else {
      updatedUncheckedStoryTypes.push(storyType);
    }
    setUncheckedStoryTypes(updatedUncheckedStoryTypes);
  };
  const storyTypes = (() => {
    const previousStoryTypes = previousData?.workspace2?.cycleLeadTimeReport?.viewConfig?.storyTypes;

    // Optimistic update
    if (!viewConfig?.storyTypes && previousStoryTypes) {
      return {
        ...previousStoryTypes,
        edges: previousStoryTypes.edges.map(storyEdge => {
          if (storyEdge.__typename !== 'SelectItemEdgeOption') {
            return storyEdge;
          }
          return {
            ...storyEdge,
            // The optimistic update is based on the `selectStoryTypesInput` and the previous `storyTypes`.
            isSelected: selectStoryTypesInput.includes(storyEdge.value)
          };
        })
      };
    }
    return viewConfig?.storyTypes;
  })();
  const isCustomTypeDisabled = Boolean(viewConfig?.chartTypeSelector?.valueOptions.edges?.find(el => el.__typename === 'SelectItemEdgeOption' && isCustomChartTypeOptionValue(el.value) && el.isDisabled));
  return ___EmotionJSX(ReportCard, {
    title: "Cycle Time",
    description: "Time spent on work.",
    Explanation: Explanation,
    Filters: () => isUnstartedIteration ? ___EmotionJSX(ReportCardHeaderEmptyState, {
      iterationStartDate: startDate,
      workspaceUtcOffset: workspaceUtcOffset
    }) : ___EmotionJSX(React.Fragment, null, timeUnitSelectorEnabled ? ___EmotionJSX(TimeUnitSelect, {
      selector: viewConfig?.timeUnitSelector,
      isLoading: loading,
      onChange: handleSetTimeUnit
    }) : null, ___EmotionJSX(WorkflowSelect, {
      selector: viewConfig?.workflowSelector,
      isLoading: loading,
      onChange: handleWorkflowChange
    }), ___EmotionJSX(ChartTypeSelect, {
      selector: viewConfig?.chartTypeSelector,
      isLoading: loading,
      onChange: handleSetChartType
    }), ___EmotionJSX(WorkflowStateRangeSelect, {
      chartType: chartType ?? viewConfig?.chartTypeSelector?.value,
      options: viewConfig?.workflowStateSelector?.valueOptions?.edges,
      label: viewConfig?.workflowStateSelector?.valueLabel,
      isLoading: loading,
      setStartingWorkflowStateIds: setStartingWorkflowStateIds,
      setEndingWorkflowstateIds: setEndingWorkflowstateIds,
      switchToCustom: () => setChartType('custom'),
      isDefaultOpen: openStatesbyDefault,
      handleIsDefaultOpen: setOpenStatesByDefault,
      isCustomDisabled: isCustomTypeDisabled
    }), ___EmotionJSX(ScaleSelect, {
      selector: generateScaleSelectorObject(scale),
      isLoading: loading,
      onChange: handleSetScale
    })),
    refetchChartCb: refetchChartData,
    chartId: CHART_ID
  }, ___EmotionJSX("div", {
    style: {
      minHeight: isUnstartedIteration ? CHART_NO_DATA_HEIGHT : CYCLE_TIME_CHART_HEIGHT,
      position: 'relative'
    }
  }, loading && !data ? ___EmotionJSX(ChartLoadingOverlay, null) : null, loading && data ?
  // This state is triggered by hitting the chart refresh - just provides some visual feedback
  ___EmotionJSX(CycleTimeSkeleton, null) : ___EmotionJSX(CycleTime, {
    chartData: chartData,
    isUnstartedIteration: isUnstartedIteration,
    storyTypes: storyTypes,
    handleToggleStoryType: handleToggleStoryType,
    yAxisLabel: data?.workspace2?.cycleLeadTimeReport?.viewConfig?.timeUnitSelector?.valueDisplayable.displayableName || 'Days',
    scale: scale
  })));
};
CycleTimeReportCardInner.displayName = "CycleTimeReportCardInner";
export const CycleTimeReportCard = props => {
  const pageName = getCurrentPage();
  return ___EmotionJSX("div", {
    "data-perma-id": "cycle-time-container"
  }, ___EmotionJSX(ChartErrorBoundary, {
    chart: "cycle time",
    pageName: pageName,
    chartId: CHART_ID
  }, ___EmotionJSX(CycleTimeReportCardInner, props)));
};
CycleTimeReportCard.displayName = "CycleTimeReportCard";