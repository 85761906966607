import { useEffect, useMemo, useRef } from 'react';
import { useToggleState } from '@clubhouse/shared/hooks';
import { useMapState } from '@clubhouse/shared/hooks/useMapState';
import { unstable_batchedUpdates } from 'react-dom';
import { createContext, useContextSelector } from 'use-context-selector';
import { jsx as ___EmotionJSX } from "@emotion/react";
const Context = createContext(null);
export function InViewProvider(_ref) {
  let {
    children,
    rootMargin
  } = _ref;
  const observerRef = useRef(null);
  const map = useMapState();
  useEffect(() => {
    observerRef.current = new IntersectionObserver(entries => {
      unstable_batchedUpdates(() => {
        for (const entry of entries) {
          const fn = map.get(entry.target);
          if (fn) fn(entry.isIntersecting);
        }
      });
    }, {
      rootMargin
    });
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
        observerRef.current = null;
      }
    };
  }, [map, rootMargin]);
  return ___EmotionJSX(Context.Provider, {
    value: useMemo(() => {
      return {
        register(el, fn) {
          map.set(el, fn);
          if (observerRef.current) observerRef.current.observe(el);
          return () => {
            map.remove(el);
            if (observerRef.current) observerRef.current.unobserve(el);
          };
        }
      };
    }, [map])
  }, children);
}
InViewProvider.displayName = "InViewProvider";
const useRegister = () => useContextSelector(Context, data => {
  if (!data) throw new Error('This can only be called from within a InViewProvider!');
  return data.register;
});
export const useInView = (el, onVisibilityChange) => {
  const [isVisible, toggleVisible] = useToggleState(false, onVisibilityChange);
  const register = useRegister();
  useEffect(() => {
    if (!el) return toggleVisible.off();
    return register(el, toggleVisible.toggleOrSet);
  }, [register, el, toggleVisible]);
  return isVisible;
};